import JSZip from "jszip";
import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router";
import { downloadFile, fetchFile, getFileName, listFiles } from "./s3Utils";
import { ThemeProvider } from "@emotion/react";
import theme from "./theme";
import {
  Grid,
  Typography,
  Divider,
  Button,
  CircularProgress,
  CssBaseline,
  Skeleton,
} from "@mui/material";
import { Download } from "@mui/icons-material";
import FileList from "./FileList";
import TopBar from "./TopBar";

function App() {
  const { caseNumber } = useParams();

  const [corporation, setCorporation] = useState("");
  const [violationDetails, setViolationDetails] = useState("");
  const [plaintiff, setPlaintiff] = useState("");

  const [downloadingAllFiles, setDownloadingAllFiles] = useState(false);
  const [loading, setLoading] = useState(true);

  const [files, setFiles] = useState([]);

  async function handleDownloadAll() {
    setDownloadingAllFiles(true);
    const settled = await Promise.allSettled(
      files?.map(({ Key }) => {
        return new Promise(async (resolve, _) => {
          const data = await fetchFile(Key, false);
          resolve({ blobData: data, Key });
        });
      }),
    );
    setDownloadingAllFiles(false);

    if (settled.some(({ status }) => status === "rejected")) {
      return;
    }
    const data = settled.map(({ value }) => value);
    const zip = new JSZip();
    data.forEach(({ blobData, Key }) => {
      zip.file(getFileName(Key), blobData);
    });
    const blob = await zip.generateAsync({ type: "blob" });
    downloadFile(blob, `${caseNumber}.zip`);
  }

  function getViolationDetails(infringementTypeId, plaintiffIsMultiple) {
    let infringementType = "";
    switch (infringementTypeId?.toString()) {
      case "3": // Trademark(s) and Copyright(s)
      case "4": // Trademark(s), Copyright(s), Rights of Publicity
        infringementType =
          "(1) trademark infringement and counterfeiting, (2) copyright infringement, and (3) false designation of origin";
        break;
      case "2": // Copyright(s)
      case "6": // Copyright(s) and Rights of Publicity
        infringementType = "copyright infringement";
        break;
      default:
        // Trademark(s)
        // Trademark(s) and Rights of Publicity
        infringementType =
          "(1) trademark infringement and counterfeiting and (2) false designation of origin";
    }

    const result = plaintiffIsMultiple ? "Plaintiffs have" : "Plaintiff has";

    return `${result} charged Defendants with violations of United States federal
      laws prohibiting ${infringementType}.`;
  }

  const loadData = useCallback(async (caseNumber) => {
    setLoading(true);
    const data = await listFiles(caseNumber);
    if (!data?.info) {
      // could not find, case probably doesn't exist
      window.location.href = "/";
      return;
    }
    const { plaintiffName, infringementTypeId, plaintiffIsMultiple } =
      data.info;

    setCorporation(plaintiffName ?? "ABC Corporation");
    setPlaintiff(plaintiffName);
    setViolationDetails(
      getViolationDetails(infringementTypeId, plaintiffIsMultiple),
    );
    setFiles(data.files ?? []);
    setLoading(false);
  }, []);

  useEffect(() => {
    loadData(caseNumber);
  }, [caseNumber, loadData]);

  const Content = () => (
    <Grid
      container
      justifyContent="center"
      spacing={3}
      sx={{
        mx: { xs: 0, sm: 1, md: 2 },
        my: { xs: 0, sm: 2, md: 4 },
        textAlign: "center",
      }}
    >
      <Grid item xs={12} xl={8}>
        <Typography variant="h4">
          NOTICE TO DEFENDANTS | CASE NO. {caseNumber ?? 1}
        </Typography>
      </Grid>
      <Grid item xs={12} xl={8}>
        <Typography variant="h6">
          {corporation ?? 1} v. The Partnerships and Unincorporated Associations
          Identified on Schedule A
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        xl={8}
        sx={{
          textAlign: "left",
        }}
      >
        <Typography variant="body1">
          The Defendants operating the Seller Aliases listed in Schedule A to
          the Complaint are hereby advised:
        </Typography>
        <Typography variant="body1">
          {violationDetails ?? 1} A copy of the Complaint, Summons, and other
          legal documents may be downloaded below.
        </Typography>
        <Typography>
          Any answer, or other response to the Complaint, should be filed with
          the Clerk of the Court, United States District Court for the Northern
          District of Illinois, Eastern Division, Chicago, Illinois within
          twenty-one (21) days from when the summons is served upon you. If no
          appearance or pleading is filed, the Court may render a default
          judgment against the Defendants.
        </Typography>
        <Typography>Defendants are advised to seek legal counsel.</Typography>
      </Grid>
      <Grid xs={12} xl={8} sx={{ mt: 4 }}>
        <Typography variant="h5">
          To initiate resolution to this matter, contact {plaintiff ?? "Plaintiff Name"}{" "}
          attorney:
        </Typography>
        <br />
        <Typography variant="h6">
          TME Law
          <br />
          E-Mail: <a href="mailto:attorney@tme-law.com">attorney@tme-law.com</a>
          <br />
          Telephone: <a href="tel:+1 708-475-1127">+1 708-475-1127</a>
        </Typography>
      </Grid>
      <Grid xs={8} sx={{ mt: 3 }}>
        <Divider />
      </Grid>
      <Grid item xs={12} xl={8}>
        <Typography variant="h4">Case Documents</Typography>
      </Grid>
      <Grid item xs={12} xl={8} >
        <Button
          onClick={handleDownloadAll}
          variant="contained"
          disabled={downloadingAllFiles || files.length === 0}
          color="primary"
        >
          Download All
          {downloadingAllFiles ? <CircularProgress size={20} /> : <Download />}
        </Button>
      </Grid>
      <Grid item xs={12} xl={8}>
        <FileList files={files} />
      </Grid>
    </Grid>
  );

  const SkeletonContent = () => (
    <Grid container item xs={12} justifyContent="center" sx={{ mt: 2 }}>
      <Skeleton variant="rounded" width={1200} height={720} />
    </Grid>
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Grid container justifyContent="left" alignItems="top">
        <Grid item xs={12}>
          <TopBar />
        </Grid>
        <Grid item xs={11}>
          {loading ? <SkeletonContent /> : <Content />}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default App;
