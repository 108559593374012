import {
  Typography,
  Grid,
  AppBar,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import { Email, Menu as MenuIcon } from "@mui/icons-material";
import { useState } from "react";

function TopBar() {
  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <AppBar position="static" color="secondary">
          <Grid
            container
            item
            xs={12}
            justifyContent="right"
            alignItems="center"
            sx={{ px: 2, py: 0.5 }}
          >
            <Typography
              variant="body2"
              component="a"
              href="mailto:attorney@tme-law.com"
              sx={{
                textDecoration: "none",
                color: "inherit",
                cursor: "pointer",
              }}
            >
              <Email fontSize="small" /> &nbsp;EMAIL : ATTORNEY@TME-LAW.COM
            </Typography>
          </Grid>
        </AppBar>
      </Grid>
      <Grid item xs={12}>
        <AppBar position="static">
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={1}
            sx={{ px: 2, py: 2 }}
          >
            <Grid
              container
              item
              xs={6}
              justifyContent="left"
              alignContent="center"
            >
              <Typography
                variant="h6"
                component="a"
                href="https://tmelawpc.com"
                sx={{
                  textDecoration: "none",
                  color: "inherit",
                  cursor: "pointer",
                  fontFamily: "Times New Roman",
                }}
              >
                TME Law, P.C.
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={6}
              justifyContent="right"
              alignItems="center"
              spacing={2}
              sx={{ display: { xs: "inherit", md: "none" } }}
            >
              <Grid item xs={2}>
                <IconButton onClick={handleOpenNavMenu} color="inherit">
                  <MenuIcon />
                </IconButton>
              </Grid>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "inherit", md: "none" },
                }}
              >
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography
                    variant="body1"
                    component="a"
                    href="https://tmelawpc.com"
                    sx={{
                      textDecoration: "none",
                      color: "inherit",
                      cursor: "pointer",
                    }}
                  >
                    HOME
                  </Typography>
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography
                    variant="body1"
                    component="a"
                    href="https://tmelawpc.com/meet-our-legal-team/"
                    sx={{
                      textDecoration: "none",
                      color: "inherit",
                      cursor: "pointer",
                    }}
                  >
                    MEET OUR LEGAL TEAM
                  </Typography>
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography
                    variant="body1"
                    component="a"
                    href="https://tmelawpc.com/contact-us"
                    sx={{
                      textDecoration: "none",
                      color: "inherit",
                      cursor: "pointer",
                    }}
                  >
                    CONTACT US
                  </Typography>
                </MenuItem>
              </Menu>
            </Grid>
            <Grid
              container
              item
              xs={6}
              justifyContent="right"
              spacing={2}
              sx={{ display: { xs: "none", md: "inherit" } }}
            >
              <Grid item>
                <Typography
                  variant="body1"
                  component="a"
                  href="https://tmelawpc.com"
                  sx={{
                    textDecoration: "none",
                    color: "inherit",
                    cursor: "pointer",
                  }}
                >
                  HOME
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body1"
                  component="a"
                  href="https://tmelawpc.com/meet-our-legal-team/"
                  sx={{
                    textDecoration: "none",
                    color: "inherit",
                    cursor: "pointer",
                  }}
                >
                  MEET OUR LEGAL TEAM
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body1"
                  component="a"
                  href="https://tmelawpc.com/contact-us"
                  sx={{
                    textDecoration: "none",
                    color: "inherit",
                    cursor: "pointer",
                  }}
                >
                  CONTACT US
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </AppBar>
      </Grid>
    </Grid>
  );
}
export default TopBar;
