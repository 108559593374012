import {
  Paper,
  Typography,
  List,
  ListItem,
  IconButton,
  ListItemText,
  Avatar,
  ListItemAvatar,
} from "@mui/material";
import { Download } from "@mui/icons-material";
import { useState } from "react";
import { getFileName, fetchFile, getFileSize } from "./s3Utils";
import FileIcon from "./FileIcon";

function FileList({ files }) {
  const [downloadingFiles, setDownloadingFiles] = useState([]);

  async function handleDownloadFile(fileName) {
    setDownloadingFiles((oldFiles) => [...oldFiles, fileName]);
    await fetchFile(fileName);
    setDownloadingFiles((oldFiles) =>
      oldFiles.filter((file) => file !== fileName),
    );
  }

  return (
    <Paper elevation={2}>
      <List>
        {files.length === 0 && (
          <Typography variant="h4">
            There are no files for this case.
          </Typography>
        )}
        {files.map(({ Key, Size }) => (
          <ListItem
            key={Key}
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="delete"
                color="primary"
                disabled={downloadingFiles.includes(Key)}
                onClick={handleDownloadFile.bind(null, Key)}
              >
                <Download />
              </IconButton>
            }
          >
            <ListItemAvatar>
              <Avatar>
                <FileIcon fileName={Key} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={getFileName(Key)}
              secondary={getFileSize(Size)}
            />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
}

export default FileList;
