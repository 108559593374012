import { PictureAsPdf, Image, Description } from "@mui/icons-material";

function FileIcon({ fileName }) {
  const extension = fileName?.split(".").pop();
  switch (extension) {
    case "pdf":
      return <PictureAsPdf />;
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
      return <Image />;
    default:
      return <Description />;
  }
}

export default FileIcon;
