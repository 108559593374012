import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { createHashRouter, RouterProvider } from "react-router-dom";

const RedirectToTmeLawPc = () => {
  window.location.href = "https://tmelawpc.com";
  return null;
};

const router = createHashRouter([
  {
    path: "/case/:caseNumber",
    element: <App />,
  },
  {
    path: "*",
    element: <RedirectToTmeLawPc />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
