import AWS from "aws-sdk";
import byteSize from "byte-size";
import axios from "axios";
import { BUCKET_URL_PREFIX, BUCKET_NAME, LAWYER_FIRM_ID } from "./constants";

async function parseInfoFile(file) {
  let infoData = await fetchFile(file.Key, false);
  infoData = await infoData?.text();
  return JSON.parse(infoData ? infoData : "{}");
}

function sortFiles(filesToSort) {
  return filesToSort.sort((a, b) =>
    getFileName(a.Key).localeCompare(getFileName(b.Key)),
  );
}

/**
 * @returns {files: [], info?: {}}, or undefined if something went wrong.
 */
async function listFiles(caseNumber) {
  const s3 = new AWS.S3({ region: "eu-west-2" });
  const bucketParams = {
    Bucket: BUCKET_NAME,
    Prefix: `${LAWYER_FIRM_ID.toString()}/${caseNumber ?? ""}`,
  };
  let data;
  try {
    data = await s3
      .makeUnauthenticatedRequest("listObjectsV2", bucketParams)
      .promise();
  } catch (e) {
    console.log(e);
    return undefined;
  }
  const contents = data?.Contents;
  const toReturn = {};
  if (contents) {
    if (contents.length > 0) {
      // remove entries that represent folders
      contents.filter(({ Size }) => Size !== 0);
    } else {
      // TODO: NOT FOUND
      return undefined;
    }
    const infoFileIndex = contents.findIndex((f) =>
      f.Key?.endsWith("/info.json"),
    );
    const infoFile = contents[infoFileIndex];
    if (infoFileIndex > -1) {
      contents.splice(infoFileIndex, 1);
      toReturn.info = await parseInfoFile(infoFile);
    }
  }
  toReturn.files = sortFiles(contents);
  return toReturn;
}

async function fetchFile(fileName, download = true) {
  if (!fileName) {
    return null;
  }
  const response = await axios({
    url: encodeFile(fileName),
    method: "GET",
    responseType: "blob",
  });
  if (response.status !== 200) {
    return null;
  }
  if (download) {
    downloadFile(response.data, getFileName(fileName));
  } else {
    return response.data;
  }
}

function downloadFile(data, filename, mime, bom) {
  var blobData = typeof bom !== "undefined" ? [bom, data] : [data];
  var blob = new Blob(blobData, {
    type: mime ? mime : "application/octet-stream",
  });
  if (typeof window.navigator.msSaveBlob !== "undefined") {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename);
  } else {
    var blobURL =
      window.URL && window.URL.createObjectURL
        ? window.URL.createObjectURL(blob)
        : window.webkitURL.createObjectURL(blob);
    var tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.href = blobURL;
    tempLink.setAttribute("download", filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === "undefined") {
      tempLink.setAttribute("target", "_blank");
    }

    document.body.appendChild(tempLink);
    tempLink.click();

    // Fixes "webkit blob resource error 1"
    setTimeout(function () {
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(blobURL);
    }, 200);
  }
}

const encodeFile = (fileName) => {
  return `${BUCKET_URL_PREFIX}/${encodeURIComponent(fileName).replace(
    /%20/g,
    "+",
  )}`;
};

function getFileName(fileName) {
  const fileSplit = fileName?.split("/");
  if (!fileSplit || fileSplit.length < 3) {
    return "(No file name)";
  }
  let file = fileSplit[2];
  if (file.split("##~~##").length > 1) {
    file = file.split("##~~##")[1];
  } else if (file.split("##__##").length > 1) {
    file = file.split("##__##")[1];
  }

  return file ? file : "(No file name)";
}

function getFileSize(size) {
  const { value, unit } = byteSize(size);
  return `${value} ${unit}`;
}

export {
  listFiles,
  downloadFile,
  fetchFile,
  encodeFile,
  getFileName,
  getFileSize,
};
